<template>
  <div class="home" :class=[state.imgRandom]>
    <div class="logo">
      <img src="@/assets/images/icon/image_logo.png" />
      <span style="color: #ffffff;">{{ _this.t('loginTitle.title') }}</span>
    </div>
    <iframe class="frame" :src="state.loginurl"></iframe>
  </div>
</template>
<script setup>
import { onMounted, reactive, inject, ref } from 'vue'
import { useRouter } from 'vue-router'
import * as Base64 from 'js-base64'
const _this = inject('_this')
onMounted(() => {
  // 接收 iframe 子页面中发出的消息
  window.addEventListener('message', handleEvent)
})
const userInfoGlobal = ref(inject('$userInfoGlobal'))
const router = useRouter()
const state = reactive({
  userInfoGlobal,
  loginurl: window.SITE_CONFIG.websiteBaseUrl + '/#' + window.SITE_CONFIG.indexUrl + '?page=' + Base64.encode(window.location.href) + '&source=4', // 登录页url
  imgRandom: '' // 随机背景图样式名
})
// 随机背景
const randomBackground = () => {
  state.imgRandom = 'img' + Math.floor(Math.random() * 6) // 随机背景
}
randomBackground()
const handleEvent = (msg) => {
  // 判断是否是字符串 子页面中发出的消息内容
  if (msg && msg.data === 'login') {
    router.push('/front/Personal')
  }
}
</script>
<style lang="scss" scoped>
  .home {
    width: 100%;
    height: 100vh;
    min-height: 700PX;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .img0 {
    background: url(../assets/images/background/bg_1.jpg) no-repeat;
    background-size: 100% 100%;
  }
  .img1 {
    background: url(../assets/images/background/bg_2.jpg) no-repeat;
    background-size: 100% 100%;
  }
  .img2 {
    background: url(../assets/images/background/bg_3.jpg) no-repeat;
    background-size: 100% 100%;
  }
  .img3 {
    background: url(../assets/images/background/bg_4.jpg) no-repeat;
    background-size: 100% 100%;
  }
  .img4 {
    background: url(../assets/images/background/bg_5.jpg) no-repeat;
    background-size: 100% 100%;
  }
  .img5 {
    background: url(../assets/images/background/bg_6.jpg) no-repeat;
    background-size: 100% 100%;
  }
  @media screen and (min-width: 700px) { /*屏幕宽 >= 700px*/
    .logo {
      position: absolute;
      top: 3vw;
      left: 3vw;
      font-size: 48px;
      display: flex;
      align-items: center;
      font-weight: bold;
    }
    .logo img {
      width: 60px;
    }
    .frame {
      width: 700PX;
      height: 570PX;
      display: flex;
      border: 0;
      border-radius: 25px;
    }
  }
  @media screen and (max-width: 700px) { /*屏幕宽 <= 700px*/
    .logo {
      position: absolute;
      top: 3vw;
      left: 3vw;
      font-size: 24PX;
      display: flex;
      align-items: center;
      font-weight: bold;
    }
    .logo img {
      width: 30PX;
    }
    .frame {
      width: 370PX;
      margin-top: 20PX;
      height: 500PX;
      border-radius: 20PX;
      border: 0;
    }
  }
</style>
